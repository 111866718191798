import { Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import styles from './../HappyFoxHelpdesk/HappyFoxHelpdesk.module.css'

import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import { CONFIRM_MESSAGES, TOAST_MESSAGES } from 'Constants/messages'
import { SYNC_STATUS } from 'Constants/syncStatus'
import PageTabs, { PageTab, PageTabList } from 'Layout/PageTabs'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import SyncDisabledAlert from '../../../components/SyncDisabledAlert'
import KnowledgeConfiguration from '../../../KnowledgeConfiguration'
import { useAppDetailContext } from '../../AppDetailContext'
import AuthenticationForm from './AuthenticationForm'
import InstallServiceDesk from './Install'
import ManageServiceDesk from './Manage'

const ServiceDeskTabs = ({ app, workspaceId }) => {
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [enableSync, enableSyncResult] = api.useEnableSyncHfServiceDeskAppMutation()

  const { title } = useAppDetailContext()

  const handleEnableSync = () => {
    if (enableSyncResult.isLoading) return

    const promise = enableSync({ workspace_id: workspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, TOAST_MESSAGES.ENABLE_SYNC(title))
  }

  const isHFServiceDeskSyncEnabled = app.sync_info.is_sync_enabled
  const isHFServiceDeskExpired = app.is_service_desk_account_expired

  const EnableSyncModalBody = () => {
    return (
      <span className={styles.EnableSyncContainer}>
        {isHFServiceDeskExpired && (
          <Text variant='muted' className={styles.SyncAlert}>
            {CONFIRM_MESSAGES.HF_ACCOUNT_EXPIRED_ALERT(title)}
          </Text>
        )}
        {CONFIRM_MESSAGES.ENABLE_APP_SYNC(title)}
      </span>
    )
  }

  const syncDisabledForAccountExpiryMessage = SYNC_STATUS.DISABLED_FOR_EXPIRY.replace('{{title}}', title).replace(
    '{{account_type}}',
    'service desk'
  )

  return (
    <Fragment>
      {!isHFServiceDeskSyncEnabled && !isHFServiceDeskExpired && (
        <SyncDisabledAlert onEnableSync={() => setIsEnableSyncModalOpen(true)} />
      )}
      {!isHFServiceDeskSyncEnabled && isHFServiceDeskExpired && (
        <SyncDisabledAlert
          message={syncDisabledForAccountExpiryMessage}
          onEnableSync={() => setIsEnableSyncModalOpen(true)}
        />
      )}
      <PageTabs pathSensitive>
        <PageTabList>
          <PageTab to='' title='Ticket configuration' />
          <PageTab to='knowledge-configuration' title='Knowledge configuration' />
        </PageTabList>
        <Routes>
          <Route path='' element={<ManageServiceDesk app={app} />} />
          <Route path='knowledge-configuration' element={<KnowledgeConfiguration app={app} />} />
          <Route path='*' element={<Navigate to='' replace />} />
        </Routes>
      </PageTabs>
      <ConfirmationModal
        isOpen={isEnableSyncModalOpen}
        message={<EnableSyncModalBody />}
        onCancel={() => setIsEnableSyncModalOpen(false)}
        onConfirm={handleEnableSync}
        title='Enable Sync'
      />
    </Fragment>
  )
}

const HappyFoxServiceDesk = () => {
  const { currentWorkspaceId } = useWorkspace()

  const { data: app = {}, isFetching } = api.useGetHfServiceDeskAppQuery({ workspace_id: currentWorkspaceId })
  const navigate = useNavigate()

  if (isFetching) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallServiceDesk app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<AuthenticationForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ServiceDeskTabs app={app} workspaceId={currentWorkspaceId} />} />
    </Routes>
  )
}

export default HappyFoxServiceDesk
