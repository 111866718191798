// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fz5xpZ6Zw83kOck4Jz_I{display:flex;align-items:center}.i_YpfILJJ1hymJkK3RLC{padding:0;align-content:center}.iMIzgVnK8x6CZuT8XiVv{color:#f2bb00;margin-right:10px}.hUjLTpMFUvmbLklSTubw{font-weight:400}.d9bsBKdIJioAxqWss9QO{padding:5px 15px}.awlNqSiTFS3q0TAAvLbO{margin-left:4px}.NpQhEKhQ3u5UAg4Ce7cu{padding:10px 15px}.jUtt9XeRBV4deDntoqdO{margin-top:20px}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Website/ConfigScreens/SitemapConfig/SitemapConfig.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,SAAA,CACA,oBAAA,CAGF,sBACE,aAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,eAAA","sourcesContent":[".TableIcon {\n  display: flex;\n  align-items: center;\n}\n\n.AlertIconTableCell {\n  padding: 0;\n  align-content: center;\n}\n\n.AlertIcon {\n  color: #F2BB00;\n  margin-right: 10px;\n}\n\n.TableHeaderText {\n  font-weight: 400;\n}\n\n.TableRow {\n  padding: 5px 15px;\n}\n\n.ValueText {\n  margin-left: 4px;\n}\n\n.TableCell {\n  padding: 10px 15px;\n}\n\n.TableContainer {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableIcon": "fz5xpZ6Zw83kOck4Jz_I",
	"AlertIconTableCell": "i_YpfILJJ1hymJkK3RLC",
	"AlertIcon": "iMIzgVnK8x6CZuT8XiVv",
	"TableHeaderText": "hUjLTpMFUvmbLklSTubw",
	"TableRow": "d9bsBKdIJioAxqWss9QO",
	"ValueText": "awlNqSiTFS3q0TAAvLbO",
	"TableCell": "NpQhEKhQ3u5UAg4Ce7cu",
	"TableContainer": "jUtt9XeRBV4deDntoqdO"
};
export default ___CSS_LOADER_EXPORT___;
