import { Button, FormControl, FormErrorText, Heading, Input, Label, Text, Textarea } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import styles from '../Actions.module.css'

import DeleteIcon from 'Icons/delete.svg'
import EndFlagIcon from 'Icons/end-flag.svg'
import StartFlagIcon from 'Icons/start-flag.svg'

import Modal, { ModalBody, ModalHeader } from 'Components/Modal'
import ReactSelect from 'Components/ReactSelect'
import { INPUT_TYPE_OPTIONS } from 'Constants/actions'
import api from 'Services/api'
import { TICKETING_INTEGRATION } from 'Src/constants/ticketingIntegration'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import { StepFormValidationSchema } from './action-form-validation-schema'
import StepForm from './StepForm'

const ActionForm = ({ isEditMode = false, onSubmit, isLoading }) => {
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [editStepIndex, setEditStepIndex] = useState(null)
  const { data: settings = [] } = api.useGetAIActionSettingsQuery()

  const createTicketSettings = settings.filter((param) => param.step_type === 'create_ticket')[0]?.settings || []

  const { currentWorkspaceId, workspaces } = useWorkspace()
  const { data: account } = api.useGetAccountQuery()
  const isHfHelpdeskTicketingConnected = account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_HELP_DESK
  const { data: ticketingAppMeta = {} } = isHfHelpdeskTicketingConnected
    ? api.useGetHelpdeskMetaInfoQuery({ workspace_id: currentWorkspaceId })
    : api.useGetHfServiceDeskMetaInfoQuery({ workspace_id: currentWorkspaceId })

  const defaultCategoryOptions = ticketingAppMeta.all_categories
  const showWorkspaceDropdown = account?.is_workspaces_enabled

  const formMethods = useForm({
    defaultValues: {
      selectedStep: {},
      parameters: [],
      message: '',
      workspace_id: currentWorkspaceId
    },
    resolver: yupResolver(StepFormValidationSchema(createTicketSettings))
  })

  const { reset: resetModalForm, setValue: setModalFormValue } = formMethods

  const {
    control,
    formState: { isDirty, errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
    watch
  } = useFormContext()

  const {
    fields: stepFields,
    append: appendStep,
    update: updateStep,
    remove: removeStep
  } = useFieldArray({
    control,
    name: 'steps'
  })

  const {
    fields: inputFields,
    append: appendInput,
    remove: removeInput
  } = useFieldArray({
    control,
    name: 'inputs'
  })

  useEffect(() => {
    if (!watch('workspace_id')) {
      setValue('workspace_id', workspaces[0].id)
    }
  }, [setValue, watch, workspaces])

  const parameters = watch('parameters')
  const inputs = watch('inputs')

  const handleStepCellClick = (index) => {
    const step = stepFields[index]

    setModalFormValue('selectedStep', { value: step.type, label: step.name })
    if (step.type === 'create_ticket') {
      const existingParamValues = watch('parameters')
      const initialParamValues = [...existingParamValues]

      step.parameters.forEach((param) => {
        initialParamValues[param.name] = param.value || ''
      })
      setModalFormValue('parameters', initialParamValues)
    } else if (step.type === 'send_message') {
      setModalFormValue('message', step.parameters[0].value || '')
    }
    setEditStepIndex(index)
    setShowModal(true)
  }

  const handleStepCreationOrUpdate = (modalData) => {
    let parameters = []

    if (modalData.selectedStep.value === 'create_ticket') {
      parameters = Object.entries(modalData.parameters).map(([name, value]) => ({
        name,
        value
      }))
      setValue('outputs', createTicketSettings?.outputs)
    } else if (modalData.selectedStep.value === 'send_message') {
      parameters = [{ name: 'message', value: modalData.message }]
    }

    if (editStepIndex !== null) {
      updateStep(editStepIndex, {
        name: modalData.selectedStep.label,
        type: modalData.selectedStep.value,
        parameters
      })
    } else {
      appendStep({
        name: modalData.selectedStep.label,
        type: modalData.selectedStep.value,
        parameters
      })
    }

    handleModalClose()
  }

  const resetValue = () => {
    resetModalForm({
      selectedStep: {},
      parameters: [],
      message: ''
    })
    setEditStepIndex(null)
  }

  const handleModalClose = () => {
    resetValue()
    setShowModal(false)
  }

  const formatInputValue = (value) => {
    return value
      .trim()
      .replace(/[^a-zA-Z\s]/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()
      .slice(0, 30)
  }

  const disableSubmitButton = isSubmitting || !isDirty || isLoading

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.ActionForm}>
      <FormControl className={styles.ActionFormTitle}>
        <Label>Title</Label>
        <Input {...register('title')} />
        {errors.title && <FormErrorText>{errors.title.message}</FormErrorText>}
      </FormControl>
      <FormControl className={styles.ActionFormDescription}>
        <Label>Description</Label>
        <Textarea {...register('description')} />
        {errors.description && <FormErrorText>{errors.description.message}</FormErrorText>}
      </FormControl>
      {!isEditMode && showWorkspaceDropdown && (
        <FormControl className={styles.ActionFormWorkspace}>
          <Label>Workspace</Label>
          <Controller
            name='workspace_id'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isDisabled
                  value={workspaces.find((opt) => opt.id === field.value)}
                  onChange={(opt) => field.onChange(opt.id)}
                  isClearable={false}
                  options={workspaces}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              )
            }}
          />
          {errors.workspace_id && <FormErrorText>{errors.workspace_id.message}</FormErrorText>}
        </FormControl>
      )}
      <hr className={styles.DividerLine} />
      <div className={styles.FieldContainer}>
        <Heading level={2}>List inputs</Heading>
        <Text variant='muted'>List any info that needs to be collected from conversation for this action</Text>
        <div className={styles.InputListContainer}>
          {inputFields.length === 0 ? (
            <div className={styles.NoStepContainer}>
              <Heading className={styles.NoStepHeading} level={6}>
                No existing inputs. Click 'Create Input' to add a new one.
              </Heading>
              <Button
                variant='primary'
                className={styles.AddStepButton}
                onClick={() => appendInput({ id: '', name: '', type: 'text' })}
              >
                Create Input
              </Button>
            </div>
          ) : (
            <Fragment>
              <div className={styles.ListInputWrapper}>
                <Label className={styles.ListInput}>Input</Label>

                <Label className={styles.ListInputPrefix}>Prefix</Label>

                <Label>Type</Label>
              </div>
              {inputFields.map((field, index) => (
                <div key={field.id} className={styles.ListInputWrapper}>
                  <Input
                    className={styles.ListInput}
                    {...register(`inputs.${index}.name`)}
                    placeholder='Input Name'
                    onChange={(e) => {
                      const { value } = e.target
                      setValue(`inputs.${index}.name`, value)
                      setValue(`inputs.${index}.id`, formatInputValue(value))
                    }}
                  />

                  <Input
                    className={styles.ListInputPrefix}
                    {...register(`inputs.${index}.id`)}
                    placeholder='Prefix'
                    disabled
                  />

                  <Controller
                    control={control}
                    name={`inputs.${index}.type`}
                    render={({ field }) => {
                      return (
                        <ReactSelect
                          {...field}
                          onChange={(opt) => field.onChange(opt.value)}
                          value={INPUT_TYPE_OPTIONS.find((opt) => opt.value === field.value)}
                          options={INPUT_TYPE_OPTIONS}
                        />
                      )
                    }}
                  />
                  {index === inputFields.length - 1 && (
                    <div
                      className={`${styles.ListButton} ${styles.ListAddButton}`}
                      onClick={() => appendInput({ id: '', name: '', type: 'text' })}
                    >
                      +
                    </div>
                  )}
                  <div className={`${styles.ListButton} ${styles.ListRemoveButton}`} onClick={() => removeInput(index)}>
                    -
                  </div>
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
      <hr className={styles.DividerLine} />
      <div className={styles.FieldContainer}>
        <Heading level={2}>Steps</Heading>
        <Text variant='muted'>Define the sequence of actions to be performed for this Action.</Text>
        <div className={styles.StepsContainerWrapper}>
          {stepFields.length === 0 ? (
            <div className={styles.NoStepContainer}>
              <Heading className={styles.NoStepHeading} level={6}>
                No existing steps. Click 'Create Step' to add a new one.
              </Heading>
              <Button
                variant='primary'
                className={styles.AddStepButton}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setShowModal(true)
                }}
              >
                Create Step
              </Button>
            </div>
          ) : (
            <div className={styles.StepsContainer}>
              <div className={styles.Step}>
                <div className={styles.StepContainerHead}>
                  <div className={styles.FlagBox}>
                    <StartFlagIcon />
                  </div>
                  <p className={styles.StepContainerHeading}>Start</p>
                </div>
                {stepFields.map((step, index) => (
                  <div key={step.id} className={styles.StepContainerBody}>
                    <div className={styles.StepWrapper}>
                      <div className={styles.StepCount}>{index + 1}</div>
                      <div className={styles.StepBox} onClick={() => handleStepCellClick(index)}>
                        <div className={styles.StepContentHead}>
                          <Heading level={6} className={styles.StepContentHeading}>
                            {step.name}
                          </Heading>
                        </div>
                        <div className={styles.StepContent}>
                          <div className={styles.StepContentLeft}>
                            <Heading level={6} className={styles.StepContentHeading}>
                              Parameters
                            </Heading>
                          </div>
                          <div className={styles.StepContentRight}>
                            {step.parameters.map((param, idx) => (idx < 2 ? <p key={idx}>{param.name}</p> : null))}
                            {step.parameters.length > 2 && <p key='ellipsis'>...</p>}
                          </div>
                        </div>
                      </div>
                      <div className={styles.RemoveLevelButtonContainer} onClick={() => removeStep(index)}>
                        <DeleteIcon width='22' height='22' fill='#909090' />
                      </div>
                    </div>
                  </div>
                ))}
                <div className={styles.AddStepButtonContainer}>
                  <div className={styles.Vr} />
                  <Button
                    variant='primary'
                    className={styles.AddStepButton}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setShowModal(true)
                    }}
                  >
                    + Add Step
                  </Button>
                </div>
                <div className={styles.StepContainerFooter}>
                  <div className={styles.FlagBox}>
                    <EndFlagIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {errors.steps && <FormErrorText>{errors.steps.message}</FormErrorText>}
      </div>

      <Modal
        style={{ content: { width: '480px', maxHeight: '800px' } }}
        isOpen={showModal}
        onRequestClose={handleModalClose}
      >
        <ModalHeader onClose={handleModalClose}>
          <Heading level={1}>{editStepIndex === null ? 'Add Step' : 'Edit Step'}</Heading>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...formMethods}>
            <StepForm
              createTicketSettings={createTicketSettings}
              parameters={parameters}
              inputs={inputs}
              stepFields={stepFields}
              categoriesOption={defaultCategoryOptions}
              onSubmit={handleStepCreationOrUpdate}
              editStepIndex={editStepIndex}
              onModalClose={handleModalClose}
            />
          </FormProvider>
        </ModalBody>
      </Modal>
      <div className={styles.ButtonContainer}>
        <Button type='submit' variant='primary' disabled={disableSubmitButton}>
          {isEditMode ? 'Save' : 'Create'}
        </Button>
        <Button variant='link-muted' onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default ActionForm
