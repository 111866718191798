import { Button, Flex, FormControl, FormErrorText, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './Manage.module.scss'

import ConfirmationModal from 'Components/ConfirmationModal'
import Link from 'Components/Link'
import MergeInput from 'Components/MergeInput'
import ReactSelect from 'Components/ReactSelect'
import { DEFAULT_TICKET_SUBJECT_MERGE_FIELD, TICKET_SUBJECT_MERGE_FIELDS } from 'Constants/mergeFields'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import AppFooter from '../../../../components/AppFooter'
import { useAppDetailContext } from '../../../AppDetailContext'
import validationSchema from './settings-form-validation-schema'

const ManageServiceDesk = (props) => {
  const { app } = props

  const { title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()
  const navigate = useNavigate()

  const [uninstall, uninstallResult] = api.useUninstallHfServiceDeskAppMutation()
  const [updateSettings, updateSettingsResult] = api.useUpdateHfServiceDeskAppMutation()

  const formMethods = useForm({
    defaultValues: {
      ticketSubject: app.subject ?? `{{${DEFAULT_TICKET_SUBJECT_MERGE_FIELD}}}`,
      allowedTeams: app.allowed_teams.map((teamId) => app.all_teams.find((team) => team.id === teamId))
    },
    resolver: yupResolver(validationSchema)
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = formMethods

  const handleFormSubmit = (data) => {
    const payload = {
      ticket_subject: data.ticketSubject,
      allowed_teams: data.allowedTeams.map((team) => team.id),
      workspace_id: currentWorkspaceId
    }

    const promise = updateSettings(payload).unwrap()

    toast.promise(promise, {
      loading: `Updating settings for ${title}`,
      success: `Successfully updated settings for ${title}`,
      error: parseErrorMessage(`Unable to update ${title} settings. Try again`)
    })
  }

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const handleUninstall = async () => {
    const toastId = toast.loading(`Uninstalling ${title}`)
    try {
      await uninstall({ workspace_id: currentWorkspaceId }).unwrap()
      setIsUninstallModalOpen(false)
      toast.success(`${title} integration uninstalled successfully`, { id: toastId })
    } catch (err) {
      setIsUninstallModalOpen(false)
      toast.error(parseErrorMessage(`Unable to uninstall ${title}. Try again`)(err), { id: toastId })
    }
  }

  const handleChangeAuthClick = () => {
    navigate('auth')
  }

  const shouldDisableSave = updateSettingsResult.isLoading || !isDirty

  return (
    <Flex direction='c' gap='md'>
      <section>
        <FormControl>
          <Label className={styles.FlexLabel}>
            You have successfully connected your HappyFox Service Desk account
            <Link variant='primary-dark' role='button' onClick={handleChangeAuthClick}>
              Change
            </Link>
          </Label>
          <Input value={app.account_url} readOnly />
        </FormControl>
      </section>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormControl>
            <Label>
              Ticket Subject <sup>*</sup>
            </Label>
            <MergeInput name='ticketSubject' mergeFields={TICKET_SUBJECT_MERGE_FIELDS} />
            {errors.ticketSubject && <FormErrorText>{errors.ticketSubject.message}</FormErrorText>}
          </FormControl>

          <FormControl>
            <Label>
              Allowed Teams <sup>*</sup>
            </Label>
            <Controller
              control={control}
              name='allowedTeams'
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    isMulti
                    isClearable={false}
                    options={app.all_teams}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                )
              }}
            />
            {errors.allowedTeams && <FormErrorText>{errors.allowedTeams.message}</FormErrorText>}
          </FormControl>

          <Button type='submit' variant='primary' className={styles.SaveButton} disabled={shouldDisableSave}>
            Save
          </Button>
        </form>
      </FormProvider>

      <AppFooter
        onUninstall={() => setIsUninstallModalOpen(true)}
        isUninstalling={uninstallResult.isLoading}
        app={app}
      />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.UNINSTALL_APP}
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
    </Flex>
  )
}

export default ManageServiceDesk
