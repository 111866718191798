import {
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './ChannelsTable.module.scss'

import Alert from 'Icons/alert.svg'

import { useFeature } from 'Components/Features'
import PageLoader from 'Components/PageLoader'
import SearchInput from 'Components/SearchInput'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import api from 'Services/api'
import {
  getInitialApiQueryParams,
  getInitialParams,
  getModeCellValue
} from 'Src/pages/protected/Channels/channels-helper'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'
import debounce from 'Utils/debounce'

import ChannelOptions from './ChannelOptions'
import EditChannelModal from './EditChannelModal'
import SlackTeamSelection from './SlackTeamSelection'

const AlertToolTip = ({ message }) => {
  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger className={styles.AlertToolTip}>
        <Alert className={styles.Alert} />
      </TooltipTrigger>
      <TooltipContent>
        <Text variant='muted'>{message}</Text>
      </TooltipContent>
    </Tooltip>
  )
}

const ChannelsTable = () => {
  const [, setSearchParams] = useSearchParams()

  const initialParams = useMemo(getInitialParams, [])
  const { workspaces } = useWorkspace()

  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams)
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const { data: account } = api.useGetAccountQuery()
  const { data = {}, isLoading } = api.useGetSlackChannelsQuery(queryParams)
  const { results: channels = [], meta: paginationDetails = {}, slack_team_id } = data

  const isWorkspacesEnabled = account?.is_workspaces_enabled
  const [slackTeamId, setSlackTeamId] = useState(null)

  const handleSlackTeamChange = (teamId) => {
    setSlackTeamId(teamId)
  }

  const { data: slackTeams = [], isLoading: isSlackTeamsLoading } = api.useGetSlackTeamsQuery()
  const showSlackTeamSelection = !isSlackTeamsLoading && slackTeams.length > 1

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }) => (
          <div className={styles.ChannelName}>
            <Text>{value}</Text>
            {row.original.workspace_id === null && <AlertToolTip message={`Workspace hasn't configured`} />}
          </div>
        )
      },
      {
        Header: 'Show ticket info publicly',
        disableSortBy: true,
        accessor: 'sync_to_channel_thread',
        Cell: ({ cell: { value } }) => (value ? 'Yes' : 'No')
      },
      {
        Header: 'Mode',
        disableSortBy: true,
        accessor: (row) => row,
        Cell: ({ cell: { value } }) => getModeCellValue(value)
      }
    ]

    if (isWorkspacesEnabled) {
      baseColumns.splice(1, 0, {
        Header: 'Workspace',
        accessor: 'workspace_id',
        Cell: ({ value }) => {
          const workspace = workspaces.find((w) => w.id === value)
          return workspace ? workspace.name : 'N/A'
        }
      })
    }

    return baseColumns
  }, [isWorkspacesEnabled, workspaces])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: channels,

      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText,
      ...(slackTeamId && { slack_team_id: slackTeamId })
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, searchText, sortBy, slackTeamId, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  const noChannelsFound = channels.length === 0

  const [channelToEdit, setChannelToEdit] = useState(null)

  const isTicketingConfigured = account?.is_ticketing_integration_configured

  const hasTicketingFeature = useFeature([
    CLIENT_FEATURE_FLAGS.ENABLE_TICKETING,
    FEATURE_FLAGS.CONVERSATIONAL_TICKETING
  ])

  const canEditChannel = isWorkspacesEnabled || (hasTicketingFeature && isTicketingConfigured)

  const handleRowClick = (channel) => {
    if (canEditChannel) {
      setChannelToEdit(channel)
    }
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <div className={styles.SearchInputContainer}>
          <SearchInput placeholder='Search Channels' value={searchText} onChange={handleSearchInputChange} />
        </div>
        {showSlackTeamSelection && <SlackTeamSelection teamId={slack_team_id} onTeamChange={handleSlackTeamChange} />}
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableActions>
      <Table className={styles.ChannelTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps())

                    return (
                      <Th key={key} isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const channel = row.original

            return (
              <TableRow key={key} {...rowProps} clickable={canEditChannel}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td
                      key={key}
                      {...cellProps}
                      style={{ display: cell.value?.convert_messages === 'automatic' ? 'flex' : '' }}
                      onClick={() => handleRowClick(channel)}
                    >
                      {cell.render('Cell')}
                      {cell.value?.convert_messages === 'automatic' && (
                        <AlertToolTip
                          message='To enable automatic ticket creation, ensure your ticket fields in Helpdesk are not mandatory (Name, Email,
Status, and Priority can remain mandatory).'
                        />
                      )}
                    </Td>
                  )
                })}
                <Td>
                  <ChannelOptions {...channel} />
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {channelToEdit && <EditChannelModal {...channelToEdit} onHide={() => setChannelToEdit(null)} />}
      {noChannelsFound && <div className={styles.NoFilterResults}>No Channels found</div>}
    </div>
  )
}

export default ChannelsTable
