// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iL1nEvIeB9hAidDPSUEx{margin-top:10px;width:-moz-fit-content;width:fit-content}.ATSctXsCTiJlM3AxRLgV:first-of-type:nth-last-of-type(2){border-bottom-left-radius:0;border-bottom-right-radius:0}.ATSctXsCTiJlM3AxRLgV:nth-of-type(2):nth-last-of-type(1){border-top-left-radius:0;border-top-right-radius:0}.ATSctXsCTiJlM3AxRLgV:not(:first-of-type):not(:last-of-type){border-radius:0;border-top:none}.ATSctXsCTiJlM3AxRLgV:first-of-type:not(:last-of-type){border-bottom-left-radius:0;border-bottom-right-radius:0}.ATSctXsCTiJlM3AxRLgV:last-of-type:not(:first-of-type){border-top-left-radius:0;border-top-right-radius:0;border-top:none}.ATSctXsCTiJlM3AxRLgV:focus{border-color:#c7c7c7}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Website/WebsiteAuthForm.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,sBAAA,CAAA,iBAAA,CAGF,wDACE,2BAAA,CACA,4BAAA,CAGF,yDACE,wBAAA,CACA,yBAAA,CAGF,6DACE,eAAA,CACA,eAAA,CAGF,uDACE,2BAAA,CACA,4BAAA,CAGF,uDACE,wBAAA,CACA,yBAAA,CACA,eAAA,CAGF,4BACE,oBAAA","sourcesContent":[".AddFieldButton {\n  margin-top: 10px;\n  width: fit-content;\n}\n\n.UrlInput:first-of-type:nth-last-of-type(2) {\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.UrlInput:nth-of-type(2):nth-last-of-type(1) {\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n}\n\n.UrlInput:not(:first-of-type):not(:last-of-type) {\n  border-radius: 0;\n  border-top: none;\n}\n\n.UrlInput:first-of-type:not(:last-of-type) {\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.UrlInput:last-of-type:not(:first-of-type) {\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  border-top: none;\n}\n\n.UrlInput:focus {\n  border-color: hsl(0, 0%, 78.0%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddFieldButton": "iL1nEvIeB9hAidDPSUEx",
	"UrlInput": "ATSctXsCTiJlM3AxRLgV"
};
export default ___CSS_LOADER_EXPORT___;
