import { TableOption, TableOptions, Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './ChannelsTable.module.scss'

import RightAngleIcon from 'Icons/arrow-right.svg'

import { SecondaryButton } from 'Components/Buttons'
import { useFeature } from 'Components/Features'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import { URLS } from 'Constants/urls'
import api from 'Services/api'
import { openUrl } from 'Utils/url'

import EditChannelModal from './EditChannelModal'

const ChannelOptionsHandler = ({ disableEdit, ...props }) => {
  const [showEditModal, setShowEditModal] = useState(false)

  return (
    <Fragment>
      <TableOptions disabled={disableEdit}>
        <TableOption onClick={() => setShowEditModal(true)}>Edit</TableOption>
      </TableOptions>
      {showEditModal && <EditChannelModal {...props} onHide={() => setShowEditModal(false)} />}
    </Fragment>
  )
}

const ChannelOptions = (props) => {
  const { data: account } = api.useGetAccountQuery()
  const isWorkspacesEnabled = account?.is_workspaces_enabled

  const navigate = useNavigate()

  const isTicketingEnabled = useFeature([CLIENT_FEATURE_FLAGS.ENABLE_TICKETING])
  const isTicketingConfigured = props?.is_ticketing_integration_configured
  const isTicketingEnabledAndConfigured = isTicketingEnabled && isTicketingConfigured

  const isConversationalTicketingEnabled = useFeature([FEATURE_FLAGS.CONVERSATIONAL_TICKETING])

  const canEditChannel = isTicketingEnabledAndConfigured && isConversationalTicketingEnabled
  const shouldUpgradePlan = !isConversationalTicketingEnabled

  const getTooltipContent = () => {
    if (shouldUpgradePlan) return `Available only in Enterprise plan`
    if (!isTicketingEnabled) return `Available only when ticketing app is connected`
    return `Available only when ticketing system is set up`
  }

  const getButtonLabel = () => {
    if (shouldUpgradePlan) return `Upgrade Plan`
    else if (!isTicketingEnabled) return `Connect Ticketing`
    return `Configure Ticketing`
  }

  const handleButtonClick = () => {
    if (shouldUpgradePlan) {
      return openUrl(URLS.ASSIST_AI)
    }
    navigate('/apps/category/ticketing-system')
  }

  const renderTooltipContent = () => (
    <Fragment>
      <Text variant='muted'>{getTooltipContent()}</Text>
      <SecondaryButton size='sm' className={styles.UpgradeButton} onClick={handleButtonClick}>
        {getButtonLabel()} <RightAngleIcon width='1em' height='1em' />
      </SecondaryButton>
    </Fragment>
  )

  const renderChannelOptionsWithTooltip = (disableEdit = false) => (
    <Tooltip delayDuration={250}>
      <TooltipTrigger className={styles.TooltipTrigger}>
        <ChannelOptionsHandler disableEdit={disableEdit} {...props} />
      </TooltipTrigger>
      <TooltipContent side='right' className={styles.TooltipContent}>
        {renderTooltipContent()}
      </TooltipContent>
    </Tooltip>
  )

  if (isWorkspacesEnabled) {
    return shouldUpgradePlan ? renderChannelOptionsWithTooltip(true) : <ChannelOptionsHandler {...props} />
  }

  return canEditChannel ? <ChannelOptionsHandler {...props} /> : renderChannelOptionsWithTooltip(true)
}

export default ChannelOptions
