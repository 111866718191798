import { FormControl, FormErrorText, Label } from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import FormWarningText from 'Components/FormWarningText'
import ReactSelect from 'Components/ReactSelect'
import { AUTOMATIC, COMMON_TEXT } from 'Constants/channels'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

const commonFieldAttributes = {
  allowedCategoriesOrTeams: {
    isMulti: true,
    isSearchable: false,
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id,
    styles: {
      control: (styles) => ({
        ...styles,
        minHeight: '5rem',
        alignItems: 'flex-start'
      })
    },
    components: {
      DropdownIndicator: null
    },
    isClearable: false
  },
  defaultCategoryOrTeam: {
    isSearchable: false,
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id
  }
}

const HelpDeskFields = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue
  } = useFormContext()

  const { currentWorkspaceId } = useWorkspace()

  const showModeSelection = watch('syncToChannelThread')
  const selectedAllowedCategories = watch('allowedCategories')
  const selectedMode = watch('mode')

  const isManualModeSelected = selectedMode && selectedMode !== AUTOMATIC
  const isAutomaticModeSelected = selectedMode === AUTOMATIC

  const showAllowedCategorySelection = showModeSelection && isManualModeSelected
  const showDefaultCategorySelectionForAutomaticMode = showModeSelection && isAutomaticModeSelected
  const showDefaultCategorySelectionForManualMode =
    showAllowedCategorySelection && selectedAllowedCategories?.length > 0

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      const { defaultCategoryForManualMode, allowedCategories = [] } = formValue

      if (name === 'allowedCategories' && type === 'change') {
        const shouldResetDefaultCategoryForManualMode =
          defaultCategoryForManualMode && !allowedCategories.some(({ id }) => id === defaultCategoryForManualMode.id)

        if (shouldResetDefaultCategoryForManualMode) {
          setValue('defaultCategoryForManualMode', null)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue])

  const { data: helpDeskData = {}, isLoading: isCategoryOptionsLoading } = api.useGetHappyFoxAppQuery({
    workspace_id: currentWorkspaceId
  })

  const allCategoryOptions = useMemo(() => {
    const { all_categories = [] } = helpDeskData
    return all_categories
  }, [helpDeskData])

  const isSelectedDefaultCategoryForManualModePrivate = watch('defaultCategoryForManualMode')?.public === false

  return (
    <Fragment>
      {showAllowedCategorySelection && (
        <FormControl isInvalid={errors.allowedCategories}>
          <Label>{COMMON_TEXT.ALLOWED_CATEGORIES}</Label>
          <Controller
            name='allowedCategories'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isLoading={isCategoryOptionsLoading}
                  options={allCategoryOptions}
                  {...commonFieldAttributes.allowedCategoriesOrTeams}
                />
              )
            }}
          />
          {errors.allowedCategories && <FormErrorText>{errors.allowedCategories.message}</FormErrorText>}
        </FormControl>
      )}

      {showDefaultCategorySelectionForAutomaticMode && (
        <FormControl isInvalid={errors.defaultCategoryForAutoMode}>
          <Label>{COMMON_TEXT.DEFAULT_CATEGORY_FOR_AUTO_MODE}</Label>
          <Controller
            name='defaultCategoryForAutoMode'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isLoading={isCategoryOptionsLoading}
                  options={allCategoryOptions}
                  {...commonFieldAttributes.defaultCategoryOrTeam}
                />
              )
            }}
          />
          {errors.defaultCategoryForAutoMode && (
            <FormErrorText>{errors.defaultCategoryForAutoMode.message}</FormErrorText>
          )}
        </FormControl>
      )}

      {showDefaultCategorySelectionForManualMode && (
        <FormControl isInvalid={errors.defaultCategoryForManualMode}>
          <Label>{COMMON_TEXT.DEFAULT_CATEGORY_FOR_MANUAL_MODE}</Label>
          <Controller
            name='defaultCategoryForManualMode'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  options={selectedAllowedCategories}
                  isClearable
                  {...commonFieldAttributes.defaultCategoryOrTeam}
                />
              )
            }}
          />
          {isSelectedDefaultCategoryForManualModePrivate && (
            <FormWarningText>{COMMON_TEXT.SELECTED_CATEGORY_IS_PRIVATE}</FormWarningText>
          )}
          {errors.defaultCategoryForManualMode && (
            <FormErrorText>{errors.defaultCategoryForManualMode.message}</FormErrorText>
          )}
        </FormControl>
      )}
    </Fragment>
  )
}

const ServiceDeskFields = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue
  } = useFormContext()

  const { currentWorkspaceId } = useWorkspace()

  const showModeSelection = watch('syncToChannelThread')
  const selectedAllowedTeams = watch('allowedTeams')
  const selectedMode = watch('mode')

  const isManualModeSelected = selectedMode && selectedMode !== AUTOMATIC
  const isAutomaticModeSelected = selectedMode === AUTOMATIC

  const showAllowedTeamSelection = showModeSelection && isManualModeSelected
  const showDefaultTeamSelectionForAutomaticMode = showModeSelection && isAutomaticModeSelected
  const showDefaultTeamSelectionForManualMode = showAllowedTeamSelection && selectedAllowedTeams?.length > 0

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      const { defaultTeamForManualMode, allowedTeams = [] } = formValue

      if (name === 'allowedTeams' && type === 'change') {
        const shouldResetDefaultTeamForManualMode =
          defaultTeamForManualMode && !allowedTeams.some(({ id }) => id === defaultTeamForManualMode.id)

        if (shouldResetDefaultTeamForManualMode) {
          setValue('defaultTeamForManualMode', null)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue])

  const { data: serviceDeskData = {}, isLoading: isTeamOptionsLoading } = api.useGetHfServiceDeskAppQuery({
    workspace_id: currentWorkspaceId
  })

  const allTeamOptions = useMemo(() => {
    const { all_teams = [] } = serviceDeskData
    return all_teams
  }, [serviceDeskData])

  const isSelectedDefaultTeamForManualModePrivate = watch('defaultTeamForManualMode')?.public === false

  return (
    <Fragment>
      {showAllowedTeamSelection && (
        <FormControl isInvalid={errors.allowedTeams}>
          <Label>{COMMON_TEXT.ALLOWED_TEAMS}</Label>
          <Controller
            name='allowedTeams'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isLoading={isTeamOptionsLoading}
                  options={allTeamOptions}
                  {...commonFieldAttributes.allowedCategoriesOrTeams}
                />
              )
            }}
          />
          {errors.allowedTeams && <FormErrorText>{errors.allowedTeams.message}</FormErrorText>}
        </FormControl>
      )}

      {showDefaultTeamSelectionForAutomaticMode && (
        <FormControl isInvalid={errors.defaultTeamForAutoMode}>
          <Label>{COMMON_TEXT.DEFAULT_TEAM_FOR_AUTO_MODE}</Label>
          <Controller
            name='defaultTeamForAutoMode'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isLoading={isTeamOptionsLoading}
                  options={allTeamOptions}
                  {...commonFieldAttributes.defaultCategoryOrTeam}
                />
              )
            }}
          />
          {errors.defaultTeamForAutoMode && <FormErrorText>{errors.defaultTeamForAutoMode.message}</FormErrorText>}
        </FormControl>
      )}

      {showDefaultTeamSelectionForManualMode && (
        <FormControl isInvalid={errors.defaultTeamForManualMode}>
          <Label>{COMMON_TEXT.DEFAULT_TEAM_FOR_MANUAL_MODE}</Label>
          <Controller
            name='defaultTeamForManualMode'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  options={selectedAllowedTeams}
                  isClearable
                  {...commonFieldAttributes.defaultCategoryOrTeam}
                />
              )
            }}
          />
          {isSelectedDefaultTeamForManualModePrivate && (
            <FormWarningText>{COMMON_TEXT.SELECTED_TEAM_IS_PRIVATE}</FormWarningText>
          )}
          {errors.defaultTeamForManualMode && <FormErrorText>{errors.defaultTeamForManualMode.message}</FormErrorText>}
        </FormControl>
      )}
    </Fragment>
  )
}

export { HelpDeskFields, ServiceDeskFields }
