// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dBXX_0_fcph5jWix3EUj{display:flex;margin-bottom:10px}.pajmWCdOn2A9ld2UZ_uU{width:400px;margin-right:10px}.PLDgjltzRDeJCWVNrRea{padding:5px 15px}.OUSJIjQpbliUl6EBkI32{font-weight:400}.NxgEaKHQaKhoYxSui3SU{display:flex;justify-content:space-between;align-items:center}.ZZlPbXPQmU7e4tueYcvy{height:-moz-fit-content;height:fit-content}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Website/ConfigScreens/SpecificUrlsConfig/SpecificUrlsConfig.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,uBAAA,CAAA,kBAAA","sourcesContent":[".UrlContainer {\n  display: flex;\n  margin-bottom: 10px;\n}\n\n.UrlInput {\n  width: 400px;\n  margin-right: 10px;\n}\n\n.TableCell {\n  padding: 5px 15px;\n}\n\n.TableHeaderText {\n  font-weight: 400;\n}\n\n.HeadingContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.AddUrlButton {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UrlContainer": "dBXX_0_fcph5jWix3EUj",
	"UrlInput": "pajmWCdOn2A9ld2UZ_uU",
	"TableCell": "PLDgjltzRDeJCWVNrRea",
	"TableHeaderText": "OUSJIjQpbliUl6EBkI32",
	"HeadingContainer": "NxgEaKHQaKhoYxSui3SU",
	"AddUrlButton": "ZZlPbXPQmU7e4tueYcvy"
};
export default ___CSS_LOADER_EXPORT___;
