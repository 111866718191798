import { Button, FormControl, FormErrorText, Heading, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './WebsiteAuthForm.module.css'

import { SecondaryButton } from 'Components/Buttons'
import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'
import parseErrorMessage from 'Src/utils/error-message-parser'

import websiteAuthFormValidationSchema from './website-auth-form-validation-schema'

const SCRAPING_STRATEGY_OPTIONS = [
  {
    value: 'full_website',
    label: 'Full Website - Retrieve content from the entire website.'
  },
  {
    value: 'only_sitemap',
    label: 'Only Sitemap - Fetch content based on the website’s sitemap.'
  },
  {
    value: 'specific_urls',
    label: 'Specific URLs - Scrape content from specific URLs.'
  }
]

const WebsiteAuthForm = ({ onCancel, app }) => {
  const [addWebsite, addWebsiteResult] = api.useAddWebsiteMutation()
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      website_name: app?.website_name || '',
      scraping_strategy: app?.scraping_strategy || '',
      website_url: app?.website_url || '',
      sitemap_url: null,
      specific_urls: []
    },
    resolver: yupResolver(websiteAuthFormValidationSchema)
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'specific_urls'
  })

  const handleConnect = useCallback(
    async ({ website_name, scraping_strategy, website_url, sitemap_url, specific_urls }) => {
      const payload = {
        website_name,
        scraping_strategy,
        website_url
      }

      if (scraping_strategy === 'only_sitemap') {
        payload.sitemap_url = sitemap_url
      } else if (scraping_strategy === 'specific_urls') {
        payload.specific_urls = specific_urls
      }

      try {
        const promise = addWebsite(payload).unwrap()
        toast.promise(promise, {
          loading: `Connecting to Website`,
          success: `Connected to Website`,
          error: parseErrorMessage(`Unable to connect to Website. Try again...`)
        })
        await promise
        onCancel()
      } catch (err) {
        toast.error(parseErrorMessage(`Unable to connect to Website. Try again...`)(err))
      }
    },
    [addWebsite, onCancel]
  )

  const scrapingStrategyValue = watch('scraping_strategy')
  const disableSubmitButton = addWebsiteResult.isLoading || isSubmitting

  return (
    <div>
      <Heading level={2}>Website Integration</Heading>
      <form onSubmit={handleSubmit(handleConnect)}>
        <FormControl isInvalid={errors.website_name}>
          <Label>Name</Label>
          <Input {...register('website_name')} />
          {errors.website_name && <FormErrorText>{errors.website_name.message}</FormErrorText>}
        </FormControl>
        <FormControl isInvalid={errors.website_url}>
          <Label>Website URL</Label>
          <Input {...register('website_url')} />
          {errors.website_url && <FormErrorText>{errors.website_url.message}</FormErrorText>}
        </FormControl>
        <FormControl isInvalid={errors.scraping_strategy}>
          <Label>How would you like to fetch content from this URL?</Label>
          <Controller
            control={control}
            name='scraping_strategy'
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={SCRAPING_STRATEGY_OPTIONS}
                value={SCRAPING_STRATEGY_OPTIONS.find((option) => option.value === field.value) || null}
                onChange={(option) => field.onChange(option.value)}
              />
            )}
          />
          {errors.scraping_strategy && <FormErrorText>{errors.scraping_strategy.message}</FormErrorText>}
        </FormControl>
        {scrapingStrategyValue === 'only_sitemap' && (
          <FormControl isInvalid={errors.sitemap_url}>
            <Label>Sitemap URL</Label>
            <Input {...register('sitemap_url')} />
            {errors.sitemap_url && <FormErrorText>{errors.sitemap_url.message}</FormErrorText>}
          </FormControl>
        )}
        {scrapingStrategyValue === 'specific_urls' && (
          <FormControl>
            <Label>Specific URLs</Label>
            {fields.map((field, index) => (
              <Input
                key={field.id}
                className={styles.UrlInput}
                {...register(`specific_urls.${index}`)}
                placeholder='Enter URL'
              />
            ))}
            <SecondaryButton className={styles.AddFieldButton} size='sm' onClick={() => append('')}>
              Add +
            </SecondaryButton>
            {errors.specific_urls && (
              <FormErrorText>At least one URL is invalid or empty. Please check your URLs.</FormErrorText>
            )}
          </FormControl>
        )}
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Submit
        </Button>
        <Button variant='link-muted' onClick={onCancel}>
          Cancel
        </Button>
      </form>
    </div>
  )
}

export default WebsiteAuthForm
