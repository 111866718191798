import { Button, Flex, FormControl, FormErrorText, FormText, Input, Label, Switch, Text } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './ManageHappyFoxHelpdesk.module.css'

import RightAngleIcon from 'Icons/arrow-right.svg'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import { useFeature } from 'Components/Features'
import Link from 'Components/Link'
import MergeInput from 'Components/MergeInput'
import ReactSelect from 'Components/ReactSelect'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import { ACCOUNT_TYPE } from 'Constants/account'
import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import { DEFAULT_TICKET_SUBJECT_MERGE_FIELD, TICKET_SUBJECT_MERGE_FIELDS } from 'Constants/mergeFields'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import { URLS } from 'Constants/urls'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'
import { useWorkspace } from 'Utils/hooks/useWorkspace'
import isEmptyObj from 'Utils/object'
import { openUrl } from 'Utils/url'

import AppFooter from '../../../components/AppFooter'
import { useAppDetailContext } from '../../AppDetailContext'
import happyfoxHelpdeskSettingsFormValidationSchema from './happyfox-helpdesk-settings-form-validation-schema'

const ManageHappyFoxHelpdesk = (props) => {
  const { app } = props
  const { data: account = {} } = api.useGetAccountQuery()

  const { title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()
  const navigate = useNavigate()

  const isConversationalTicketingEnabled = useFeature([FEATURE_FLAGS.CONVERSATIONAL_TICKETING])
  const isDMWorkflowEnabled = useFeature([CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])
  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK

  const [uninstall, uninstallResult] = api.useUninstallHappyFoxAppMutation({ workspace_id: currentWorkspaceId })
  const [updateSettings, updateSettingsResult] = api.useUpdateHappyFoxAppMutation()

  const formMethods = useForm({
    defaultValues: {
      ticketSubject: app.subject ?? `{{${DEFAULT_TICKET_SUBJECT_MERGE_FIELD}}}`,
      allowedCategories: app.allowed_categories.map((categoryId) =>
        app.all_categories.find((category) => category.id === categoryId)
      ),
      solvedTicketStatus: isEmptyObj(app.default_solved_status) ? null : app.default_solved_status,
      showTicketsFromOtherChannels: app.track_external_tickets,
      showSuggestionsForTicketsFromOtherChannels: app.show_suggestion_for_external_tickets
    },
    resolver: yupResolver(happyfoxHelpdeskSettingsFormValidationSchema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
    getValues
  } = formMethods

  const handleFormSubmit = () => {
    const data = getValues()

    const payload = {
      ticket_subject: data.ticketSubject,
      allowed_categories: data.allowedCategories.map((category) => category.id),
      default_solved_status: data.solvedTicketStatus.id,
      workspace_id: currentWorkspaceId
    }

    if (isDMWorkflowEnabled) {
      payload.track_external_tickets = data.showTicketsFromOtherChannels
      if (isSlackAccountType && isConversationalTicketingEnabled) {
        payload.show_suggestion_for_external_tickets = data.showSuggestionsForTicketsFromOtherChannels
      } else {
        payload.show_suggestion_for_external_tickets = app.show_suggestion_for_external_tickets
      }
    }

    const promise = updateSettings(payload).unwrap()

    toast.promise(promise, {
      loading: `Updating settings for ${title}`,
      success: `Successfully updated settings for ${title}`,
      error: parseErrorMessage(`Unable to update ${title} settings. Try again`)
    })
  }

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const handleUninstall = async () => {
    const toastId = toast.loading(`Uninstalling ${title}`)
    try {
      await uninstall({ workspace_id: currentWorkspaceId }).unwrap()
      setIsUninstallModalOpen(false)
      toast.success(`${title} integration uninstalled successfully`, { id: toastId })
    } catch (err) {
      setIsUninstallModalOpen(false)
      toast.error(parseErrorMessage(`Unable to uninstall ${title}. Try again`)(err), { id: toastId })
    }
  }

  const handleChangeAuthClick = () => {
    navigate('auth')
  }

  const showSuggestionsForTicketsLabel = () => {
    const label = <Label>Show suggestions for tickets created from other channels</Label>
    if (isConversationalTicketingEnabled) {
      return label
    } else {
      return (
        <Tooltip delayDuration={250}>
          <TooltipTrigger className={styles.TooltipTrigger}>{label}</TooltipTrigger>
          <TooltipContent side='right' className={styles.UpgradeTooltip}>
            <Text variant='muted'>Available only in Enterprise plan</Text>
            <SecondaryButton
              size='sm'
              className={styles.UpgradeButton}
              onClick={(e) => {
                e.stopPropagation()
                openUrl(URLS.ASSIST_AI)
              }}
            >
              Upgrade Plan <RightAngleIcon width='1em' height='1em' />
            </SecondaryButton>
          </TooltipContent>
        </Tooltip>
      )
    }
  }

  const showTicketsFromOtherChannels = watch('showTicketsFromOtherChannels')
  const disableShowSuggestionsForTicketsFromOtherChannels =
    !isConversationalTicketingEnabled || !showTicketsFromOtherChannels

  useEffect(() => {
    if (!showTicketsFromOtherChannels) {
      setValue('showSuggestionsForTicketsFromOtherChannels', false, { shouldDirty: true })
    }
  }, [setValue, showTicketsFromOtherChannels])

  const shouldDisableSave = updateSettingsResult.isLoading || !isDirty

  return (
    <Flex direction='c' gap='md'>
      <section>
        <FormControl>
          <Label className={styles.FlexLabel}>
            You have successfully connected your HappyFox Help Desk account
            <Link variant='primary-dark' role='button' onClick={handleChangeAuthClick}>
              Change
            </Link>
          </Label>
          <Input value={app.account_url} readOnly />
          <FormText>Example: https://myaccount.helpdesk.com</FormText>
        </FormControl>
      </section>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormControl>
            <Label>
              Ticket Subject <sup>*</sup>
            </Label>
            <MergeInput name='ticketSubject' mergeFields={TICKET_SUBJECT_MERGE_FIELDS} />
            {errors.ticketSubject && <FormErrorText>{errors.ticketSubject.message}</FormErrorText>}
          </FormControl>

          <FormControl>
            <Label>
              Allowed categories <sup>*</sup>
            </Label>
            <Controller
              control={control}
              name='allowedCategories'
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    isMulti
                    isClearable={false}
                    options={app.all_categories}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                )
              }}
            />
            {errors.allowedCategories && <FormErrorText>{errors.allowedCategories.message}</FormErrorText>}
          </FormControl>

          <FormControl className={styles.Status}>
            <Label>
              Status for solved tickets <sup>*</sup>
            </Label>
            <Controller
              control={control}
              name='solvedTicketStatus'
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    options={app.solved_statuses}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                )
              }}
            />
            {errors.solvedTicketStatus && <FormErrorText>{errors.solvedTicketStatus.message}</FormErrorText>}
          </FormControl>

          {isDMWorkflowEnabled && (
            <Fragment>
              <FormControl className={styles.Toggle}>
                <Flex direction='r' gap='md' align='start'>
                  <Controller
                    name='showTicketsFromOtherChannels'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => {
                      return <Switch checked={value} onCheckedChange={onChange} ref={ref} />
                    }}
                  />
                  <Label>Show tickets created from other channels in Assist AI</Label>
                </Flex>

                {errors.showTicketsFromOtherChannels && (
                  <FormErrorText>{errors.showTicketsFromOtherChannels.message}</FormErrorText>
                )}
              </FormControl>

              {isSlackAccountType && (
                <FormControl disabled={disableShowSuggestionsForTicketsFromOtherChannels}>
                  <Flex direction='r' gap='md' align='start'>
                    <Controller
                      name='showSuggestionsForTicketsFromOtherChannels'
                      control={control}
                      render={({ field: { value, onChange, ref } }) => {
                        return (
                          <Switch
                            className={styles.Switch}
                            checked={value}
                            onCheckedChange={onChange}
                            ref={ref}
                            disabled={disableShowSuggestionsForTicketsFromOtherChannels}
                          />
                        )
                      }}
                    />
                    {showSuggestionsForTicketsLabel()}
                  </Flex>

                  {errors.showSuggestionsForTicketsFromOtherChannels && (
                    <FormErrorText>{errors.showSuggestionsForTicketsFromOtherChannels.message}</FormErrorText>
                  )}
                </FormControl>
              )}
            </Fragment>
          )}

          <Button type='submit' variant='primary' className={styles.SaveButton} disabled={shouldDisableSave}>
            Save
          </Button>
        </form>
      </FormProvider>
      <AppFooter
        onUninstall={() => setIsUninstallModalOpen(true)}
        isUninstalling={uninstallResult.isLoading}
        app={app}
      />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.UNINSTALL_APP}
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
    </Flex>
  )
}

export default ManageHappyFoxHelpdesk
