import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

import InstallWebsite from './InstallWebsite'
import ManageWebsite from './ManageWebsite'
import WebsiteAuthForm from './WebsiteAuthForm'

const Website = () => {
  const { isLoading, data: app = {} } = api.useGetWebsiteQuery()

  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallWebsite app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<WebsiteAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageWebsite app={app} />} />
    </Routes>
  )
}

export default Website
