import { Heading, Table, TableBody, TableHead, TableRow, Td, Text, Th } from '@happyfoxinc/react-ui'
import { Fragment, useMemo } from 'react'
import { useTable } from 'react-table'

import styles from './SitemapConfig.module.css'

import AlertIcon from 'Icons/alert.svg'
import CheckIcon from 'Icons/check-green-circle-inverted.svg'
import CrossIcon from 'Icons/cross-red-circle-inverted.svg'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

const SitemapConfig = ({ scrapingStrategy, app }) => {
  const { website_id } = app
  const isFullWebsiteScrapingStrategy = scrapingStrategy === 'full_website'

  const queryParams = {
    website_id
  }

  if (!isFullWebsiteScrapingStrategy) {
    queryParams.status = 'failed'
  }

  const { isLoading, data = {} } = api.useGetWebsiteSyncQuery(queryParams)
  const { results: websiteSyncedData = [] } = data

  const overallTableColumns = useMemo(() => {
    return [
      {
        Header: 'Site URL',
        accessor: 'website_url'
      },
      {
        Header: 'Successfully Synced',
        accessor: (d) => d.sync_info.successful_syncs,
        Cell: ({ cell: { value } }) => (
          <div className={styles.TableIcon}>
            <CheckIcon />
            <Text className={styles.ValueText}>{value}</Text>
          </div>
        )
      },
      {
        Header: 'Failed',
        accessor: (d) => d.sync_info.failed_syncs,
        Cell: ({ cell: { value } }) => (
          <div className={styles.TableIcon}>
            <CrossIcon />
            <Text className={styles.ValueText}>{value}</Text>
          </div>
        )
      }
    ]
  }, [])

  const failedUrlsTableColumns = useMemo(() => {
    return [
      {
        Header: 'Failed URLs',
        accessor: 'webpage_url'
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns: overallTableColumns,
    data: [app]
  })

  const {
    getTableProps: getFailedTableProps,
    headerGroups: failedHeaderGroups,
    getTableBodyProps: getFailedTableBodyProps,
    rows: failedRows,
    prepareRow: prepareFailedRow
  } = useTable({
    columns: failedUrlsTableColumns,
    data: websiteSyncedData
  })

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div>
      <Heading level={6}>Currently syncing from</Heading>
      <Table className={styles.CollectionsTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps()

                    return (
                      <Th key={key} {...headerProps} className={styles.TableCell}>
                        <Text className={styles.TableHeaderText} variant='muted'>
                          {column.render('Header')}
                        </Text>
                      </Th>
                    )
                  })}
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td className={styles.TableCell} key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {!isFullWebsiteScrapingStrategy && websiteSyncedData.length > 0 && (
        <div className={styles.TableContainer}>
          <Heading level={6}>Failed URLs</Heading>
          <Table className={styles.CollectionsTable} {...getFailedTableProps()}>
            <TableHead>
              {failedHeaderGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                return (
                  <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
                    <Fragment>
                      {headerGroup.headers.map((column) => {
                        const { key, ...headerProps } = column.getHeaderProps()

                        return (
                          <Th key={key} {...headerProps} className={styles.TableCell}>
                            <Text className={styles.TableHeaderText} variant='muted'>
                              {column.render('Header')}
                            </Text>
                          </Th>
                        )
                      })}
                      <Th isOption />
                    </Fragment>
                  </TableRow>
                )
              })}
            </TableHead>

            <TableBody {...getFailedTableBodyProps()}>
              {failedRows.map((row) => {
                prepareFailedRow(row)
                const { key, ...rowProps } = row.getRowProps()

                return (
                  <TableRow key={key} {...rowProps} clickable>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      return (
                        <Td className={styles.TableCell} key={key} {...cellProps}>
                          {cell.render('Cell')}
                        </Td>
                      )
                    })}
                    <Td className={styles.AlertIconTableCell}>
                      <AlertIcon className={styles.AlertIcon} height='20' width='20' />
                    </Td>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default SitemapConfig
