import { Button, Flex, FormControl, FormErrorText, Heading, Label, Text } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './ConfigureModal.module.scss'

import MergeInput from 'Components/MergeInput'
import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'
import { SuccessSwitch } from 'Components/Switch'
import {
  AI_ACTION_DESCRIPTIONS,
  APPROVAL_STATUS_OPTIONS,
  TICKET_CREATION_OPTIONS,
  TICKET_SUBJECT_TEMPLATE
} from 'Constants/aiActions'
import { AI_ACTION_MERGE_FIELDS } from 'Constants/mergeFields'
import Sheet from 'Layout/Sheet'
import api from 'Services/api'
import { TICKETING_INTEGRATION } from 'Src/constants/ticketingIntegration'
import { useAppDetailContext } from 'Src/pages/protected/Apps/AppDetails/AppDetailContext'
import parseErrorMessage from 'Utils/error-message-parser'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import validationSchema from './validationSchema'

const ConfigureModal = ({ data, onClose, isDefaultEnabled }) => {
  const id = data.id

  const { currentWorkspaceId } = useWorkspace()

  const [getAgents, getAgentsResult] = api.useLazyGetAgentsQuery()
  const { data: account } = api.useGetAccountQuery()

  const isTicketingIntegrationConnected = account.is_ticketing_integration_connected
  const isHfHelpdeskTicketingConnected = account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_HELP_DESK
  const isHfServicedeskTicketingConnected =
    account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_SERVICE_DESK

  const { data: ticketingAppMeta = {}, isLoading: ticketingAppMetaLoading } = isHfHelpdeskTicketingConnected
    ? api.useGetHelpdeskMetaInfoQuery({ workspace_id: currentWorkspaceId })
    : api.useGetHfServiceDeskMetaInfoQuery({ workspace_id: currentWorkspaceId })

  const [updateAppsAIAction, updateAppsAIActionResult] = api.useUpdateAppsAIActionMutation()

  const formMethods = useForm({
    defaultValues: {
      is_enabled: data.is_enabled,
      is_approval_needed: data.settings?.is_approval_needed || false,
      approvers: data.settings?.approvers,
      is_ticketing_enabled: data.settings?.is_ticketing_enabled || false,
      ...(isHfHelpdeskTicketingConnected && {
        default_category: data.settings?.default_category || null
      }),
      ...(isHfServicedeskTicketingConnected && {
        default_team: data.settings?.default_team || null
      }),
      subject_template: data.settings?.subject_template ?? TICKET_SUBJECT_TEMPLATE[id],
      default_solved_status: data.settings?.default_solved_status || null
    },
    resolver: yupResolver(validationSchema(isHfHelpdeskTicketingConnected))
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setValue
  } = formMethods

  const isEnabled = watch('is_enabled')
  const isApprovalNeeded = watch('is_approval_needed')
  const isTicketingEnabled = watch('is_ticketing_enabled')
  const defaultTeam = watch('default_team')

  const { id: appName } = useAppDetailContext()

  useEffect(() => {
    if (isDefaultEnabled) {
      setValue('is_enabled', true, {
        shouldDirty: true
      })
    }
  }, [isDefaultEnabled, setValue])

  useEffect(() => {
    if (!isEnabled) {
      setValue('is_approval_needed', false)
      setValue('is_ticketing_enabled', false)
    }
  }, [isEnabled, setValue])

  const loadAgentOptions = (inputValue, cb) => {
    getAgents(inputValue)
      .unwrap()
      .then((data) => {
        const options = data.map(({ user_profile_id: id, name, email }) => ({ id, name, email }))
        cb(options)
      })
  }

  const formatAgentsOptionLabel = ({ name, email }, { context }) => {
    if (context === 'menu') {
      return `${name} (${email})`
    }
    return name
  }

  const handleFormSubmit = (formData) => {
    const payload = {
      is_enabled: formData.is_enabled,
      is_approval_needed: formData.is_approval_needed,
      is_ticketing_enabled: formData.is_ticketing_enabled
    }

    if (formData.is_approval_needed) {
      payload.approvers = formData.approvers?.map(({ id }) => parseInt(id))
    }

    if (formData.is_ticketing_enabled) {
      const categoryOrTeamProperty = isHfHelpdeskTicketingConnected ? 'default_category' : 'default_team'
      payload[categoryOrTeamProperty] = parseInt(formData[categoryOrTeamProperty]?.id)
      payload.default_solved_status = parseInt(formData.default_solved_status?.id)
      payload.subject_template = formData.subject_template
    }

    const promise = updateAppsAIAction({ appName, id, payload }).unwrap()

    toast.promise(promise, {
      loading: 'Updating action...',
      success: () => {
        onClose()
        return 'Action updated successfully'
      },
      error: parseErrorMessage('Unable to update action. Try again...')
    })
  }

  const shouldDisableButton = updateAppsAIActionResult.isLoading || !isDirty

  const defaultCategoryOptions = ticketingAppMeta.all_categories
  const defaultTeamOptions = ticketingAppMeta.all_teams

  const statusOption = isHfHelpdeskTicketingConnected
    ? ticketingAppMeta.solved_statuses
    : ticketingAppMeta.solved_statuses?.incident?.[defaultTeam?.id]

  return (
    <Sheet title='Configure Action' onHide={onClose}>
      {ticketingAppMetaLoading && <PageLoader />}
      {!ticketingAppMetaLoading && (
        <FormProvider {...formMethods}>
          <form className={styles.FormContainer} onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles.ModalHeader}>
              <div className={styles.TitleContainer}>
                <Heading level={2}>{data.name}</Heading>
                <FormControl>
                  <Controller
                    name='is_enabled'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => {
                      return <SuccessSwitch checked={value} onCheckedChange={onChange} ref={ref} />
                    }}
                  />
                  {errors.is_enabled && <FormErrorText>{errors.is_enabled.message}</FormErrorText>}
                </FormControl>
              </div>
              <Text size='sm' variant='muted'>
                {AI_ACTION_DESCRIPTIONS[id]}
              </Text>
            </div>
            <div className={styles.ActionContainer}>
              <Text className={styles.ActionTitle}>Need Approval?</Text>
              <FormControl>
                <Label>Select Approval Status</Label>
                <Controller
                  control={control}
                  name='is_approval_needed'
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={APPROVAL_STATUS_OPTIONS}
                      value={APPROVAL_STATUS_OPTIONS.find((option) => option.value === field.value)}
                      onChange={(option) => field.onChange(option.value)}
                      isDisabled={!isEnabled}
                    />
                  )}
                />
                {errors.is_approval_needed && <FormErrorText>{errors.is_approval_needed.message}</FormErrorText>}
              </FormControl>
              {isApprovalNeeded && isEnabled && (
                <FormControl isInvalid={errors.approvers}>
                  <Label> Select Agents</Label>
                  <Controller
                    control={control}
                    name='approvers'
                    render={({ field }) => {
                      return (
                        <ReactSelect
                          {...field}
                          className={styles.Select}
                          formatOptionLabel={formatAgentsOptionLabel}
                          getOptionValue={({ id }) => id}
                          placeholder='Type to search'
                          isClearable={false}
                          isMulti
                          options={[]}
                          loadOptions={loadAgentOptions}
                          isLoading={getAgentsResult.isLoading}
                          loadingMessage={() => 'Searching for agents...'}
                          minCharsBeforeLoadingOptions={2}
                          noOptionsMessage={({ inputValue }) => {
                            if (inputValue.length < 2) {
                              return 'Type alteast 2 characters to start searching'
                            }
                            return `No agents found for input "${inputValue}"`
                          }}
                          components={{
                            DropdownIndicator: null
                          }}
                        />
                      )
                    }}
                  />
                  {errors.approvers && <FormErrorText>{errors.approvers.message}</FormErrorText>}
                </FormControl>
              )}
            </div>
            <hr className={styles.DividerLine} />
            <div className={styles.ActionContainer}>
              {!isTicketingIntegrationConnected && (
                <div className={styles.InfoContainer}>
                  Ticket creation is not allowed due to the integration not being connected
                </div>
              )}
              <Text className={styles.ActionTitle}>Ticket Creation</Text>
              <FormControl>
                <Label>Would you like to create ticket for this action</Label>
                <Controller
                  control={control}
                  name='is_ticketing_enabled'
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={TICKET_CREATION_OPTIONS}
                      value={TICKET_CREATION_OPTIONS.find((option) => option.value === field.value)}
                      onChange={(option) => field.onChange(option.value)}
                      isDisabled={!isEnabled || !isTicketingIntegrationConnected}
                    />
                  )}
                />
                {errors.is_ticketing_enabled && <FormErrorText>{errors.is_ticketing_enabled.message}</FormErrorText>}
              </FormControl>
              {isTicketingEnabled && isEnabled && (
                <Fragment>
                  {isHfHelpdeskTicketingConnected && (
                    <FormControl>
                      <Label>Select Category</Label>
                      <Controller
                        control={control}
                        name='default_category'
                        render={({ field }) => {
                          return (
                            <ReactSelect
                              {...field}
                              options={defaultCategoryOptions}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                            />
                          )
                        }}
                      />
                      {errors.default_category && <FormErrorText>{errors.default_category.message}</FormErrorText>}
                    </FormControl>
                  )}
                  {isHfServicedeskTicketingConnected && (
                    <FormControl>
                      <Label>Select Team </Label>
                      <Controller
                        control={control}
                        name='default_team'
                        render={({ field }) => {
                          return (
                            <ReactSelect
                              {...field}
                              options={defaultTeamOptions}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              onChange={(option) => {
                                field.onChange(option)
                                setValue('default_solved_status', null)
                              }}
                            />
                          )
                        }}
                      />
                      {errors.default_team && <FormErrorText>{errors.default_team.message}</FormErrorText>}
                    </FormControl>
                  )}
                  <FormControl>
                    <Label>Ticket Closed Subject</Label>
                    <MergeInput name='subject_template' mergeFields={AI_ACTION_MERGE_FIELDS} />
                    {errors.subject_template && <FormErrorText>{errors.subject_template.message}</FormErrorText>}
                  </FormControl>
                  {statusOption && (
                    <FormControl>
                      <Label>Ticket Closed Status</Label>
                      <Controller
                        control={control}
                        name='default_solved_status'
                        render={({ field }) => {
                          return (
                            <ReactSelect
                              {...field}
                              options={statusOption}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                            />
                          )
                        }}
                      />
                      {errors.default_solved_status && (
                        <FormErrorText>{errors.default_solved_status.message}</FormErrorText>
                      )}
                    </FormControl>
                  )}
                </Fragment>
              )}
            </div>
            <Flex className={styles.ButtonContainer}>
              <Button type='submit' variant='primary' disabled={shouldDisableButton}>
                Save
              </Button>
              <Button variant='link-muted' onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </form>
        </FormProvider>
      )}
    </Sheet>
  )
}

export default ConfigureModal
