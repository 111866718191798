import useToggleState from 'Src/utils/hooks/useToggleState'

import InstallApp from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'
import WebsiteAuthForm from './WebsiteAuthForm'

const InstallWebsite = ({ app }) => {
  const { title } = useAppDetailContext()
  const [showCredentialsForm, toggleCredentialsForm] = useToggleState(false)

  if (showCredentialsForm) {
    return <WebsiteAuthForm onCancel={toggleCredentialsForm} app={app} />
  }

  return <InstallApp name={title} onInstall={toggleCredentialsForm} />
}

export default InstallWebsite
