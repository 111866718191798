import * as yup from 'yup'

const websiteAuthFormValidationSchema = yup.object().shape({
  website_name: yup.string().required('Website name is required'),
  website_url: yup.string().required('Website URL is required').url('Please enter a valid URL'),
  scraping_strategy: yup
    .string()
    .required('Please choose a scraping strategy')
    .oneOf(['full_website', 'only_sitemap', 'specific_urls'], 'Invalid scraping strategy'),
  sitemap_url: yup
    .string()
    .notRequired()
    .when('scraping_strategy', {
      is: 'only_sitemap',
      then: (schema) => schema.required('Sitemap URL is required').url('Please enter a valid URL')
    }),
  specific_urls: yup
    .array()
    .of(
      yup
        .string()
        .required('URL is required')
        .test('is-url', 'Please enter a valid URL', (value) => {
          const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
          return urlRegex.test(value)
        })
    )
    .required('At least one URL is required')
    .test('no-empty-string', 'At least one URL is invalid or empty', (value) => {
      return value.every((url) => url !== '')
    })
})

export default websiteAuthFormValidationSchema
