// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jw1wJr1jao9TIwWAMWNZ{margin-top:2rem}.JdswW9_7DGpspA1_g2at{width:20rem;margin-right:auto}.y45CiFI9od7mC8eWO1Dy .O4DjIoe9yk0MSffSGLSu{border:none;background:rgba(0,0,0,0);padding-left:8px}.y45CiFI9od7mC8eWO1Dy .V1pFOeMrR6UyY59hAe3b{height:20px;color:var(--primary-solid)}.f20J8PXnsc8DOnSAvv6L{text-align:center;margin:5rem 0}.SRtI493bcJLmXlql7fvp{border:none;background-color:unset}.VkW2VcjThegUMNNHKHaW{display:flex;flex-direction:column;gap:.7rem;align-items:center}.K3c6o0lcqt5drfiWppuA{display:flex;align-items:center;gap:.4rem}.kM3NB5G7nug63QwZmVvu{display:flex}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Channels/ChannelsTable/ChannelsTable.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CAIA,4CACE,WAAA,CACA,wBAAA,CACA,gBAAA,CAEF,4CACE,WAAA,CACA,0BAAA,CAIJ,sBACE,iBAAA,CACA,aAAA,CAGF,sBACE,WAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA,CAGF,sBACE,YAAA","sourcesContent":[".Container {\n  margin-top: 2rem;\n}\n\n.SearchInputContainer {\n  width: 20rem;\n  margin-right: auto;\n}\n\n.ChannelTable {\n  .AlertToolTip {\n    border: none;\n    background: transparent;\n    padding-left: 8px;\n  }\n  .Alert {\n    height: 20px;\n    color: var(--primary-solid);\n  }\n}\n\n.NoFilterResults {\n  text-align: center;\n  margin: 5rem 0;\n}\n\n.TooltipTrigger {\n  border: none;\n  background-color: unset;\n}\n\n.TooltipContent {\n  display: flex;\n  flex-direction: column;\n  gap: 0.7rem;\n  align-items: center;\n}\n\n.UpgradeButton {\n  display: flex;\n  align-items: center;\n  gap: 0.4rem;\n}\n\n.ChannelName {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Jw1wJr1jao9TIwWAMWNZ",
	"SearchInputContainer": "JdswW9_7DGpspA1_g2at",
	"ChannelTable": "y45CiFI9od7mC8eWO1Dy",
	"AlertToolTip": "O4DjIoe9yk0MSffSGLSu",
	"Alert": "V1pFOeMrR6UyY59hAe3b",
	"NoFilterResults": "f20J8PXnsc8DOnSAvv6L",
	"TooltipTrigger": "SRtI493bcJLmXlql7fvp",
	"TooltipContent": "VkW2VcjThegUMNNHKHaW",
	"UpgradeButton": "K3c6o0lcqt5drfiWppuA",
	"ChannelName": "kM3NB5G7nug63QwZmVvu"
};
export default ___CSS_LOADER_EXPORT___;
