import {
  FormControl,
  FormErrorText,
  Heading,
  Input,
  PaginationControls,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'
import * as yup from 'yup'

import styles from './SpecificUrlsConfig.module.css'

import AlertIcon from 'Icons/alert.svg'
import CheckIcon from 'Icons/check-green-circle-inverted.svg'
import CrossIcon from 'Icons/cross-red-circle-inverted.svg'

import { SecondaryButton } from 'Components/Buttons'
import api from 'Services/api'
import PageLoader from 'Src/components/PageLoader'

const addUrlFormValidation = yup.object().shape({
  site_url: yup.string().required('URL required').url('Please enter a valid url')
})

const SpecificUrlsConfig = ({ app }) => {
  const { website_id } = app

  const queryParams = {
    website_id
  }

  const [addWebsiteSync, addWebsiteSyncState] = api.useAddWebsiteSyncMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ resolver: yupResolver(addUrlFormValidation) })

  const [pageSize] = useState(10)
  const [pageIndex, setPageIndex] = useState(0)

  const { isLoading, data = {} } = api.useGetWebsiteSyncQuery({
    ...queryParams,
    offset: pageIndex * pageSize,
    limit: pageSize
  })
  const { results: websiteSyncedData = [], meta: paginationDetails = {} } = data

  const columns = useMemo(() => {
    return [
      {
        Header: 'Site URLs',
        accessor: 'webpage_url'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex: tablePageIndex }
  } = useTable(
    {
      columns,
      data: websiteSyncedData,
      initialState: { pageSize, pageIndex },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize)
    },
    usePagination
  )

  useEffect(() => {
    setPageIndex(tablePageIndex)
  }, [tablePageIndex])

  const onSubmit = useCallback(
    (formData) => {
      const promise = addWebsiteSync({ id: website_id, data: formData })

      toast.promise(promise, {
        loading: 'Adding URL...',
        success: 'URL added successfully',
        error: 'Failed to add URL'
      })

      promise.then(() => {
        reset()
      })
    },
    [addWebsiteSync, website_id, reset]
  )

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Fragment>
      <Heading level={6}>Add URL here to sync additional information</Heading>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.UrlContainer}>
        <FormControl isInvalid={errors.site_url}>
          <Input placeholder='Enter URL' {...register('site_url')} className={styles.UrlInput} />
          {errors.site_url && <FormErrorText>{errors.site_url.message}</FormErrorText>}
        </FormControl>
        <SecondaryButton type='submit' className={styles.AddUrlButton} disabled={addWebsiteSyncState.isLoading}>
          Add URL
        </SecondaryButton>
      </form>
      <div className={styles.HeadingContainer}>
        <Heading level={6}>Currently syncing from</Heading>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
      <Table className={styles.CollectionsTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
                {headerGroup.headers.map((column) => {
                  const { key, ...headerProps } = column.getHeaderProps()

                  return (
                    <Th key={key} {...headerProps} className={styles.TableCell}>
                      <Text className={styles.TableHeaderText} variant='muted'>
                        {column.render('Header')}
                      </Text>
                    </Th>
                  )
                })}
                <Th />
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()

            return (
              <TableRow key={key} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()

                  return (
                    <Td key={key} {...cellProps} className={styles.TableCell}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
                <Td>
                  {row.original.status === 'success' ? (
                    <CheckIcon />
                  ) : row.original.status === 'pending' ? (
                    <AlertIcon color='#f0b800f2' height='20px' width='20px' />
                  ) : row.original.status === 'failed' ? (
                    <CrossIcon />
                  ) : null}
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Fragment>
  )
}

export default SpecificUrlsConfig
