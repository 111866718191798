import * as yup from 'yup'

import { AUTOMATIC, COMMON_TEXT } from 'Constants/channels'

const getValidationSchema = (isHfHelpdeskTicketingConnected) => {
  const allowedCategoriesOrTeamsKey = isHfHelpdeskTicketingConnected ? 'allowedCategories' : 'allowedTeams'
  const defaultCategoryOrTeamForAutoModeKey = isHfHelpdeskTicketingConnected
    ? 'defaultCategoryForAutoMode'
    : 'defaultTeamForAutoMode'
  const defaultCategoryOrTeamForManualModeKey = isHfHelpdeskTicketingConnected
    ? 'defaultCategoryForManualMode'
    : 'defaultTeamForManualMode'

  const { ALLOWED_CATEGORIES_ERROR, ALLOWED_TEAMS_ERROR, DEFAULT_CATEGORY_ERROR, DEFAULT_TEAM_ERROR } = COMMON_TEXT

  const allowedCategoriesOrTeamsError = isHfHelpdeskTicketingConnected ? ALLOWED_CATEGORIES_ERROR : ALLOWED_TEAMS_ERROR
  const defaultCategoryOrTeamError = isHfHelpdeskTicketingConnected ? DEFAULT_CATEGORY_ERROR : DEFAULT_TEAM_ERROR

  return yup
    .object()
    .shape({
      syncToChannelThread: yup.boolean().required('Sync to Channel is required'),
      mode: yup
        .string()
        .nullable()
        .when('syncToChannelThread', {
          is: true,
          then: (schema) => schema.required('Field is required'),
          otherwise: (schema) => schema.notRequired()
        }),
      workspace_id: yup.string().when('$showWorkspaceDropdown', {
        is: true,
        then: (schema) => schema.required('Workspace is required'),
        otherwise: (schema) => schema.notRequired()
      }),
      [allowedCategoriesOrTeamsKey]: yup
        .array()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) => syncToChannelThread && mode && mode !== AUTOMATIC,
          then: (schema) => schema.min(1, allowedCategoriesOrTeamsError).required(allowedCategoriesOrTeamsError)
        }),
      [defaultCategoryOrTeamForAutoModeKey]: yup
        .object()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) => syncToChannelThread && mode === AUTOMATIC,
          then: (schema) => schema.required(defaultCategoryOrTeamError)
        }),
      [defaultCategoryOrTeamForManualModeKey]: yup.object().notRequired()
    })
    .required()
}
export default getValidationSchema
